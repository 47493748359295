*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.z-\[998\] {
  z-index: 998;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-\[14px\] {
  margin-left: 14px;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-\[-12px\] {
  margin-top: -12px;
}

.mt-\[100px\] {
  margin-top: 100px;
}

.flex {
  display: flex;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[100\%\] {
  height: 100%;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-\[64px\] {
  width: 64px;
}

.w-\[800px\] {
  width: 800px;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-\[1200px\] {
  max-width: 1200px;
}

.max-w-\[1440px\] {
  max-width: 1440px;
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-\[50px\] {
  gap: 50px;
}

.rounded-\[150px\] {
  border-radius: 150px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.bg-\[\#faebd7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 235 215 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[54px\] {
  font-size: 54px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.header {
  z-index: 999;
  background-color: #f3efeb;
  border-radius: 20px;
  justify-content: space-between;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 10px 10px -10px #00000080;
}

.body {
  margin: 0 !important;
}

.error-page {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.empty-menu-body {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  display: flex;
}

.empty-menu-button {
  cursor: pointer;
  background-color: #f3efeb;
  border: 0;
  border-radius: 10px;
  width: 100px;
  height: 60px;
  text-decoration: none;
}

.img-container {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 20px;
  display: flex;
}

.rating {
  gap: 10px;
  display: flex;
}

.rating-icon {
  width: 20px;
  height: 20px;
}

.logo {
  width: 50px;
  height: 50px;
}

.nav-items > ul {
  font-size: 24px;
  list-style-type: none;
  display: flex;
}

.nav-items > ul > li {
  padding: 20px;
}

.res-container {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.res-card {
  background-color: #faebd7;
  border-radius: 20px;
  width: 250px;
  height: 450px;
  margin: 10px;
  padding: 10px;
  transition: transform .3s, box-shadow .3s;
  box-shadow: 10px 10px 6px #0000001a;
}

.res-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 10px -10px #27262680;
}

.res-logo {
  border-radius: 15px;
  width: 240px;
  height: 150px;
  padding: 5px;
}

.app {
  max-width: 1440px;
  margin: auto;
}

.body {
  padding: 20px;
}

.filter {
  gap: 10px;
  display: flex;
}

.search {
  gap: 4px;
  display: flex;
}

.delivery {
  justify-content: space-between;
  display: flex;
}

.shimmer-container {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.shimmer-card {
  background-color: #f0f0f0;
  border-radius: 20px;
  width: 250px;
  height: 420px;
  margin: 10px;
}

.no-restaurants-container {
  flex-wrap: wrap;
  place-content: center;
  width: 100%;
  height: 500px;
  display: flex;
}

.no-restaurants-data {
  place-content: center;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.custom-link {
  color: #000;
  text-decoration: none;
}

.menu-body {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.res-menu-logo {
  border-radius: 40px;
  width: 300px;
  height: 200px;
}

.res-name-container {
  justify-content: space-between;
  justify-self: left;
  width: 100%;
  margin-top: 12px;
  display: flex;
}

.res-name {
  text-wrap: wrap;
  flex-wrap: wrap;
  font-size: 24px;
  font-weight: bolder;
  display: flex;
}

.res-menu-cuisines {
  text-wrap: wrap;
  flex-wrap: wrap;
}

.res-area-container {
  gap: 12px;
  display: flex;
}

.res-distance {
  margin-top: 3px;
  font-weight: bold;
}

.res-area {
  text-wrap: wrap;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 400;
}

.res-expectations {
  text-align: left;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  display: flex;
}

.menu {
  text-align: left;
  width: 100%;
}

.cost-for-two {
  font-weight: bold;
}

.res-menu {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.menu-card-body {
  background-color: #e8dfdf;
  border-radius: 20px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
}

.res-menu-item-logo-container {
  flex-wrap: wrap;
  align-content: center;
  justify-items: center;
  width: 90px;
  display: flex;
}

.res-menu-item-logo {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 70px;
}

.item-info {
  width: 80%;
  padding: 10px;
}

.veg-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
}

.item-name {
  font-size: 16px;
  font-weight: bold;
}

.item-price {
  font-size: 14px;
  font-weight: 400;
}

.item-description {
  color: #5c5b5b;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 100;
}

.menu-body-shimmer {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.menu-logo-shimmer {
  background-color: #f0f0f0;
  border-radius: 40px;
  width: 300px;
  height: 200px;
  margin-top: 40px;
}

.res-name-shimmer {
  background-color: #f0f0f0;
  width: 200px;
  height: 28px;
}

.res-menu-cuisines-shimmer {
  background-color: #f0f0f0;
  width: 250px;
  height: 24px;
  margin-top: 10px;
}

.rating-shimmer {
  background-color: #f0f0f0;
  width: 100px;
  height: 24px;
}

.res-expectations-shimmer {
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  display: flex;
}

.res-expctation-left-shimmer, .cost-for-two-shimmer {
  background-color: #f0f0f0;
  width: 100px;
  height: 24px;
}

.menu-shimmer {
  background-color: #f0f0f0;
  width: 100px;
  height: 36px;
  margin-top: 40px;
  margin-right: auto;
}

.res-menu-shimmer {
  background-color: #f0f0f0;
  border-radius: 20px;
  width: 100%;
  height: 100px;
  margin-top: 20px;
}

.hover\:bg-\[\#dcdcdc\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 220 220 / var(--tw-bg-opacity));
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

/*# sourceMappingURL=index.d2b9a970.css.map */
